<template>
  <div class="idea">
    <head-nav></head-nav>
    <about-img></about-img>
    <div class="about-target">

      <h3 class="about-target-title">
        <img
          :src="$t('about.title-7')"
          alt=""
          :style="this.$i18n.locale==='en'?'width:170px;height:auto;':''"
        >
      </h3>
      <div class="about-target-info">
        <img
          src="@/assets/image/about/about-target-1.png"
          alt=""
        >
        <p >
          {{$t('about.about-target.list-content-1')}}
        </p>
      </div>

      <h3 class="about-target-title">
        <img
          :src="$t('about.title-8')"
          alt=""
          :style="this.$i18n.locale==='en'?'width:260px;height:auto;':''"
        />
      </h3>
      <div class="lists">
        <div
          class="list"
          v-for="(item, index) in  $t('about.about-target.list-1')"
          :key="index"
        >
          <div class="list-left">
            <div class="list-nums">
              <div>No.</div>
              <h4 class="list-num">{{  (index + 1).toString().padStart(2, '0') }}</h4>
            </div>
          </div>

          <div class="list-right">
            <div class="list-img">
              <img
                class="list-imgs"
                :src="item.img"
                alt=""
              />
            </div>
            <p class="content">{{ item.title }}</p>
            <p class="contents">{{ item.content }}</p>

          </div>
        </div>
      </div>
    </div>
    <footers></footers>
  </div>

</template>

<script>

import HeadNav from './head-nav';
import AboutImg from './about-img';
import Footers from './footers';

export default {
  components: {
    HeadNav,
    AboutImg,
    Footers,
  },
  data() {
    return {
    };
  },
};
</script>

<style lang="scss" scoped>
.about-target {
  padding: 0 30px 100px 30px;
  background: #f8f8f8;

  .about-target-info {
    width: 100%;
    text-align: center;

    img {
      width: 100%;
    }

    p {
      font-size: 36px;
      margin: -60px auto 0px auto;
      white-space: nowrap;
      text-overflow: ellipsis;
      overflow: hidden;
      word-break: break-all;
    }
  }

  .about-target-title {
    text-align: center;
    width: 100%;
    height: 140px;
    text-align: center;
    padding: 50px 0 30px 0;

    img {
      height: 64px;
    }
  }

  .lists {
    width: 100%;
    display: flex;
    flex: 1;
    flex-wrap: wrap;
    justify-content: space-around;

    .list {
      width: 600px;
      height: 470px;
      border: 1px solid #eee;
      background: #fff;
      border-radius: 10px;
      margin-bottom: 20px;
      overflow: hidden;

      .list-left {
        width: 100%;
        height: 150px;
        background: #ffbf00;

        .list-nums {
          padding: 36px 24px;

          h4 {
            font-size: 72px;
          }
        }
      }

      .list-right {
        width: 100%;
        text-align: center;

        .list-img {
          margin: 50px 0 30px 0;

          img {
            width: 120px;
            height: 120px;
          }
        }

      }
    }
  }
}

@media screen and (max-width: 768px) {
  .about-target .about-target-info p {
    width: 460px;
  }
  .about-target .lists .list {
    width: 320px;
  }
}
@media screen and (max-width: 320px) {
  .about-target .about-target-info p {
    width: 400px;
    margin: -54px auto 0px auto;
  }
  .about-target .lists .list {
    width: 280px;
  }
}
</style>
