import { render, staticRenderFns } from "./about-target.vue?vue&type=template&id=abafd48a&scoped=true&"
import script from "./about-target.vue?vue&type=script&lang=js&"
export * from "./about-target.vue?vue&type=script&lang=js&"
import style0 from "./about-target.vue?vue&type=style&index=0&id=abafd48a&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "abafd48a",
  null
  
)

export default component.exports